require('jquery-mask-plugin');

$(document).ready(
    function () {
        $.jMaskGlobals.watchDataMask = true;
        var options = {
            onKeyPress: function (cpf, ev, el, op) {
                var masks = ['000.000.000-00#', '00.000.000/0000-00'];
                $('.cpfcnpjmask').mask((cpf.length > 14) ? masks[1] : masks[0], op);
            }
        }

        if($('.cpfcnpjmask')[0]) {
            $('.cpfcnpjmask')[0].value.length > 14
                ? $('.cpfcnpjmask').mask('00.000.000/0000-00', options)
                : $('.cpfcnpjmask').mask('000.000.000-00#', options);
        }

        $('.money').mask('00000,00', {reverse: true});
        $('.money').keypress(function (e) {

            if (this.value.length == 0 && e.which == 48) {
                return false;
            }

        });

        var SPMaskBehavior = function (val) {
                return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
            },
            spOptions = {
                onKeyPress: function (val, e, field, options) {
                    field.mask(SPMaskBehavior.apply({}, arguments), options);
                }
            };

        $('.phone_8_9').mask(SPMaskBehavior, spOptions);
    }
);

$(document.body).on('focus', 'input',
    function () {
        var SPMaskBehavior = function (val) {
                return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
            },
            spOptions = {
                onKeyPress: function (val, e, field, options) {
                    field.mask(SPMaskBehavior.apply({}, arguments), options);
                }
            };

        $('.phone_8_9').mask(SPMaskBehavior, spOptions);
    }
);
