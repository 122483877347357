require('./bootstrap');
require('./jquery');
require('./jquery-mask');
require('./buscacep');
require('./tinymce');
require('./bs-custom-file-input');
require('./select2');
require('./bootstrap-switch-button');
require('./chartisan');
require('./toast');
// require('./fastclick'); Tentativa de correção para o select2 no navegador mobile
