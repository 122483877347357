$(document).ready(
    function () {

        //Efeito de fade no body de todas as páginas
        $('body').fadeIn(1000).removeClass('hidden').removeClass('ds_none');

        //Scroll suave
        let $doc = $('html, body');
        $('.scrollsuave').click(function () {
            $doc.animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 500);
            return false;
        });

        /**
         * Cada botão de exclusão dos registros de um grid que invoca a modal do bootstrap conterá um
         * atributos e uma classe:
         * Atributo data-action, que é a action que o route do Laravel criou
         * Classe call-btn-confirmDeleteRegister, para invocar a função abaixo
         * O form da exclusão pertence ao modal. Então, em cada invocação da confirmação de exclusão
         * o form tem seu action alterado
         */
        $(".call-btn-confirmDeleteRegister").click(function(ev){
            let action = ev.target.getAttribute('data-action');
            $('#form-delete-register').attr('action', action);
        });
    });
