require('select2/dist/js/select2.full');
require('select2/dist/js/i18n/pt-BR')

$(document).ready(() => {
    $('.autosearch').select2({
        width: '100%',
        language: 'pt-BR',
        allowClear: true,
        placeholder: 'Selecione um registro',
        ajax: {
            url: '',
            dataType: 'json',
            delay: 500,

            processResults: function (data) {
                return {
                    results: $.map(data, function (data) {
                        return {
                            text: data.name,
                            id: data.id
                        }
                    })
                };
            },
            cache: true
        }
    });
});
